<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title>대시보드 설정</v-card-title>
        <v-card-text>내용</v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DashboardSetting',
  metaInfo: {
    title: '대시보드 설정'
  },
  created() {
    this.$store.commit('setAppBarTitle', '대시보드 설정')
  }
}
</script>
